.registrationDatatable {
    margin-top: 0px;
    height: 600px;
    padding: 20px;

    .datatableTitle {
        width: 100%;
        font-size: 24px;
        color: #7451f8;
        // color: gray;
        font-weight: bold;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .link {
            text-decoration: none;
            color: #7451f8;
            font-size: 16px;
            font-weight: 400;
            border: 1px solid #7451f8;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    .cellWithImg {
        display: flex;
        align-items: center;

        .cellImg {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
        }
    }

    .cellWithStatus {
        padding: 5px;
        border-radius: 5px;

        &.active {
            background-color: rgba(0, 128, 0, 0.05);
            color: green;
        }

        &.inactive {
            background-color: rgba(255, 0, 0, 0.05);
            color: crimson;
        }

        &.blocked {
            background-color: rgba(255, 0, 0, 0.05);
            color: crimson;
        }
    }
    .cellAction {
        display: flex;
        align-items: center;
        gap: 15px;
        width: 400px;
        /* Adjusted width to accommodate three buttons */
    }

    .editButton,
    .admitCardButton,
    .RegistrationSlipButton {
        padding: 8px 16px;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 1px solid transparent;
    }

    .editButton {
        color: darkblue;
        background-color: rgba(0, 0, 139, 0.1);
    }

    .admitCardButton {
        color: #008000;
        /* green color */
        background-color: rgba(0, 128, 0, 0.1);
    }

    .RegistrationSlipButton {
        color: #800080;
        /* purple color */
        background-color: rgba(128, 0, 128, 0.1);
    }

    .editButton:hover,
    .admitCardButton:hover,
    .RegistrationSlipButton:hover {
        border-color: rgba(0, 0, 0, 0.2);
    }

    .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
        color: black;
        // color: #7451f8;
        /* Change to the color you desire */
    }
}