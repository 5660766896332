.questionSingle {
  display: flex;
  width: 100%;

  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      gap: 20px;

      .left {
        flex: 1;
        position: relative;
        padding: 20px;
        box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);

        .editButton {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          font-size: 12px;
          color: #7451f8;
          font-weight: bold;
          background-color: #f7f6f918;
          cursor: pointer;
          border-radius: 0 0 0 5px;
          border: 1px dotted #7451f8;

          &:hover {
            background-color: rgb(181, 181, 250);
            color: #fff;
          }
        }

        .title {
          font-size: x-large;
          color:#555
        }

        .item {
          .details {
            .fieldname {
              color: black;
              font-weight: bold;
            }

            .questionImage {
              width: 350px;
              height: 350px;
              object-fit: contain;
            }

            .quiz,
            .status {
              margin-bottom: 10px;
              font-weight: bold;
            }

            .quizStatus {
              font-size: 18px;
              font-weight: normal;
              color: #333;

              &.active {
                color: green;
              }
            }

            p {
              font-size: 18px;
              font-weight: normal;
              color: #333;
              margin-bottom: 10px;
            }

            .itemTitle {
              color: black;
              margin-top: 20px;
              margin-bottom: 10px;
            }

            .questionImage {
              // max-width: auto;
              // height: auto;
              // margin: 10px 0;
              width: 100%;
              margin-top: 10px;
              margin-bottom: 10px;
              max-height: 250px;
              object-fit: contain;
              border-radius: 10px;
            }

            .options {
              
              .option {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 5px;
                flex-direction: row;
                padding: 20px;

                .optionImage {
                  width: 250px;
                  height: 250px;
                  object-fit: contain;
                }
              }
            }


            .correctImage {
              max-width: auto;
              height: auto;
              aspect-ratio: 4/3;
              object-fit: contain;
              
            }
          }
        }
      }
    }
  }
}