/* Inside sidebar.scss */
.sidebar {
  flex: 1;
  border-right: 0.5px solid rgb(230, 227, 227);
  min-height: 100vh;
  background-color: white;

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      font-size: 18px;
      font-weight: bold;
      color: #7451f8;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }

  .center {
    padding-left: 10px;
    margin-top: 20px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 12px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        &:hover {
          background-color: #f0f0f0;
          transition: background-color 0.3s ease-in-out;
        }

        .icon {
          font-size: 20px;
          color: #7451f8;
        }

        span {
          font-size: 14px;
          font-weight: 600;
          color: #888;
          margin-left: 10px;
        }

        /* Style for active menu item */
        &.active {
          background-color: #7451f8;
          color: #fff;
        }
      }
    }

    /* for the drop down */
    .dropdown {
      margin-bottom: 10px;
      margin-right: 10px;
      position: relative;

      select {
        width: 100%;
        padding: 10px;
        border: 2px solid #7451f8;
        border-radius: 5px;
        background-color: #fff;
        font-size: 14px;
        color: #333;
        outline: none;
        transition: border-color 0.3s ease-in-out;
        cursor: pointer;

        /* Highlight the selected option */
        option[selected] {
          background-color: #7451f8;
          color: #fff;
        }
      }

      select:hover,
      select:focus {
        border-color: #9a7ff4;
      }

      /* Custom arrow */
      &::after {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: #7451f8;
        pointer-events: none;
        transition: color 0.3s ease-in-out;
      }

      &:hover::after,
      select:focus~&::after {
        color: #9a7ff4;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #7451f8;
      cursor: pointer;
      margin-right: 10px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }

      &:nth-child(2) {
        background-color: #333;
      }

      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }
}