.questionNew {
    width: 100%;
    display: flex;

    .newContainer {
        flex: 6;

        .top,
        .bottom {
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 10px;
            margin: 20px;
            display: flex;

            h1 {
                color: lightgray;
                font-size: 20px;
            }

            .left {
                flex: 1;
                text-align: center;

                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .right {
                flex: 2;

                .form {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 30px;
                    justify-content: flex-start;

                    .formInput {
                        width: calc(50% - 15px);

                        label {
                            display: block;
                            margin-bottom: 8px;
                            font-weight: bold;
                        }

                        .inputContainer {
                            position: relative;

                            input[type="file"] {
                                display: none;
                            }

                            .uploadButton {
                                background-color: #7451f8;
                                color: white;
                                padding: 8px 16px;
                                border: none;
                                border-radius: 5px;
                                cursor: pointer;
                                transition: background-color 0.3s ease;

                                &:hover {
                                    background-color: #4d30b6;
                                }
                            }

                            img {
                                max-width: 100px;
                                margin-top: 8px;
                                margin-bottom: 8px;
                                display: block;
                            }
                        }

                        input[type="text"],
                        select {
                            width: 95%;
                            padding: 10px;
                            border: 1px solid #ccc;
                            border-radius: 5px;
                            transition: border-color 0.3s ease;

                            &:hover {
                                border-color: #999;
                            }

                            &:focus {
                                outline: none;
                                border-color: #555;
                            }
                        }
                    }

                    .formSubmit {
                        width: 100%;
                        text-align: right;

                        button {
                            width: 150px;
                            padding: 10px 20px;
                            background-color: #7451f8;
                            color: white;
                            border: none;
                            border-radius: 5px;
                            cursor: pointer;
                            transition: background-color 0.3s ease;

                            &:hover {
                                background-color: #4d30b6;
                            }
                        }
                    }
                }
            }

            // .right {
            //     flex: 2;

            //     .form {
            //         display: flex;
            //         flex-wrap: wrap;
            //         gap: 30px;
            //         justify-content: flex-start;

            //         .formInput {
            //             width: calc(50% - 15px);

            //             label {
            //                 display: block;
            //                 margin-bottom: 5px;
            //                 font-weight: bold;
            //             }

            //             .inputContainer {
            //                 position: relative;

            //                 label {
            //                     cursor: pointer;
            //                     position: absolute;
            //                     right: 10px;
            //                     top: 5px;
            //                 }

            //                 img {
            //                     max-width: 100px;
            //                     display: block;
            //                     margin-top: 5px;
            //                 }

            //                 input[type="file"] {
            //                     display: none;
            //                 }
            //             }

            //             select,
            //             input[type="text"] {
            //                 width: 95%;
            //                 padding: 8px;
            //                 border: 1px solid #ccc;
            //                 border-radius: 5px;
            //                 transition: border-color 0.3s ease;
            //             }

            //             select:hover,
            //             input[type="text"]:hover {
            //                 border-color: #999;
            //             }

            //             select:focus,
            //             input[type="text"]:focus {
            //                 outline: none;
            //                 border-color: #555;
            //             }

            //         }

            //         .formSubmit {
            //             width: 100%;
            //             text-align: right;

            //             button {
            //                 width: 150px;
            //                 padding: 10px 20px;
            //                 background-color: #7451f8;
            //                 color: white;
            //                 border: none;
            //                 border-radius: 5px;
            //                 cursor: pointer;
            //                 transition: background-color 0.3s ease;

            //                 &:hover {
            //                     background-color: #4d30b6;
            //                 }
            //             }
            //         }
            //     }
            // }
        }
    }
}