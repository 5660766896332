.single {
  display: flex;
  width: 100%;

  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      gap: 20px;

      .left {
        flex: 1;
        position: relative;
        padding: 20px;
        box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);

        .icon {
          color: #7451f8;
        }

        .editButton {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          font-size: 12px;
          color: #7451f8;
          font-weight: bold;
          background-color: #f7f6f918;
          cursor: pointer;
          border-radius: 0px 0px 0px 5px;
          border: 1px dotted #7451f8;

          &:hover {
            background-color: rgb(181, 181, 250);
            color: #fff;
          }
        }

        .item {
          display: flex;
          gap: 20px;

          .itemImg {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }

          .details {
            .itemTitle {
              margin-bottom: 10px;
              color: #555;
            }

            .detailItem {
              margin-bottom: 10px;
              font-size: 14px;

              .itemKey {
                font-weight: bold;
                color: gray;
                margin-right: 5px;
              }

              .itemValue {
                font-weight: normal;
              }
            }
          }
        }
      }

      .right {
        flex: 2;
      }
    }

    .bottom {
      margin: 10px 20px;
      padding: 20px;
      box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    }

    .title {
      font-size: 16px;
      color: gray;
      margin-bottom: 20px;
    }
  }
}