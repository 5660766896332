// testQuestion.scss
.test-question-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
    font-family: 'Roboto', sans-serif;
    color: #202124;
    padding: 20px;
    align-items: flex-start;
}

.card {
    width: 100%;
    max-width: 600px;
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(60, 64, 67, 0.15), 0 1px 2px rgba(60, 64, 67, 0.3);
}

.question {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
}

//
.options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.option-row {
    display: flex;
    gap: 15px;
}

.option {
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.option:hover {
    background-color: #f8f9fa;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.option input[type="radio"] {
    margin-right: 10px;
    color: #7451f8;
    margin: 10px;
}

.optionImage {
    width: 250px;
    height: 250px;
    object-fit: contain;
    margin-left: 20px;
}

// .option {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 10px;
//     border: 1px solid #ccc;
//     border-radius: 6px;
//     cursor: pointer;
//     transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;

//     &:hover {
//         background-color: #f8f9fa;
//         transform: translateY(-2px);
//         box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//     }

//     input[type="radio"] {
//         margin-right: 10px;
//         color: #7451f8;
//     }
// }

// .optionImage {
//     width: 250px;
//     height: 250px;
//     // max-height:350px;
//     object-fit: contain;
// }

.itemImg {
    width: 100%;
    max-height: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
    object-fit: contain;
    border-radius: 0px;
}

.buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.previousButton,
.nextButton {
    flex: 1;
    padding: 10px 20px;
    background-color: #7451f8;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.3s;

    &:disabled {
        background-color: #ddd;
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        background-color: #5a38d4;
        transform: scale(1.02);
    }
}

.progress {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background-color: #eee;
    overflow: hidden;
}

.progress::-webkit-progress-value {
    background-color: #5a38d4;
}

.timer {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #7451f8;
}

.loading-message {
    text-align: center;
    padding: 15% 0;
    font-size: 24px;
    color: #555;
}

.beginButton {
    background-color: #7451f8;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.5rem 1.5rem;
    transition: background-color 0.3s ease-in-out;
    margin-top: 15px;

    &:hover {
        background-color: #5a38d4;
    }
}