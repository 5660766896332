.instruction {
    display: flex;

    .homeContainer {
        flex: 6;

        .content {
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            height: auto;

            .card {
                // display: flex;
                // flex-direction: column;
                // justify-content: center;
                // align-items: stretch;
                // width: 100%;
                // height: auto;
                // padding: 20px;
                // background-color: #f5f5f5;
                // border-radius: 8px;
                // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 90vw;
                padding: 50px;
                background-color: #f2f3f4;
                border-radius: 30px;
            }

            .heading {
                font-size: 25px;
                font-weight: bold;
                margin-bottom: 20px;
                text-align: center;
            }

            .description {
                text-align: justify;
                margin-bottom: 30px;
            }

            .beginButton {
                padding: 10px 20px;
                background-color: #7451f8;
                color: white;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                font-size: 16px;
            }
            .beginButton:hover {
                padding: 10px 20px;
                background-color: #5a38d4;
                color: white;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                font-size: 16px;
            }
        }
    }
}